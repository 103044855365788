import { useEffect, useState, Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import NotFound from "./pages/web/NotFound";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Skeleton from "@mui/material/Skeleton";

const WebHome = lazy(() => import("./pages/web/Home"));

const StudentZooom = lazy(() => import("./components/Zoom/StudentZoom"));


function App() {
 
  return (
    <BrowserRouter>
    
      <ToastContainer />
      <Suspense
        fallback={
          <>
            <div className=" w-full h-[10vh] p-2">
              <Skeleton
                animation="wave"
                variant="rounded"
                sx={{ background: "gray", width: 1 }}
                height="9vh"
              />
            </div>
            <div className=" grid h-[87vh] grid-cols-6 gap-2 p-2">
              <div className=" col-span-1">
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  sx={{ background: "gray", width: 1 }}
                  height="88vh"
                />
              </div>
              <div className=" col-span-5">
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  sx={{ background: "gray", width: 1 }}
                  height="88vh"
                />
              </div>
            </div>
          </>
        }
      >
        <Routes>
          <Route path="/" element={<WebHome />} />
         
            <Route
              path="/student/zoom_class/:meeting/:password"
              element={<StudentZooom  />}
            />
           
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
